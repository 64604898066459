import { useNavigate } from "react-router-dom";
import SFactory from "../s-factory";

const S = SFactory("get-cash-now-button");

const Text = () => <S k="getCashNow">Get Cash Now</S>;

const GetCashNow = () => {
  const navigate = useNavigate();
  return <button onClick={() => navigate("/new-loan")}><Text /></button>;
};

export const BigGetCashNow = () => {
  const navigate = useNavigate();
  return <button onClick={() => navigate("/new-loan")} className="BigGetCashNow"><Text /></button>;
};

export default GetCashNow;
