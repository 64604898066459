// These are the Firebase accounts which have admin access to loan data, etc., to do account approval, loan approval, etc.
export const ADMIN_EMAILS = [
  "mishchea@gmail.com",
  "hi@mensh.app",
  "maveszpremi@gmail.com",
];

// These are the email addresses that receive a message when a user applies for a loan, etc.
export const NOTIFY_EMAILS = [
  "menshteam@gmail.com",
];

export function isAdmin(email: any) {
  return ADMIN_EMAILS.some((e) => e === email);
}

type RepChangeReason =
  | "NEW_ACCOUNT"
  | "LOAN_REPAID"
  | "NEW_FRIEND_CONNECTION"
  | "ADMIN_DISCRETIONARY"
;

type NewFriendConnectionRepChangeData = {
  other_user_id: string;
};

export type RepChange = {
  id: string;
  user_id: string;
  delta: number;
  reason: RepChangeReason;
  timestamp: number;
  data?: any;
};

export type NewFriendConnectionRepChange = RepChange & {
  data: NewFriendConnectionRepChangeData;
};

export type Status =
  | "PENDING"
  | "ACTIVE"
;

export type FirestoreUser = {
  email: string;
  borrower_status: Status;
  first_name: string;
  last_name: string;
  phone_number?: string;
  birthday: string;
  venmo_username?: string;
  paypal_username?: string;
  zelle_username?: string;
  cashapp_username?: string;
  rep: number;
};

export type ActiveFirestoreUser = FirestoreUser & {
  borrower_status: "ACTIVE";
};

export function isActive(user: FirestoreUser): user is ActiveFirestoreUser {
  return user.borrower_status === "ACTIVE";
}

export function isStatus(status: any): status is Status {
  return status === "PENDING" ||
         status === "ACTIVE";
}

export function isObject(o: any): o is object {
  return typeof o === "object" && o != null;
}

export function isFirestoreUser(user: any): user is FirestoreUser {
  // XXX: This is very incomplete.
  return isObject(user) &&
    isStatus((user as any).borrower_status);
}

export type StatusUpdate = {
  status: string;
  timestamp: number;
};

export type Loan = {
  id: string;
  user_id: string;
  status: LoanStatus;
  status_updates: LoanStatusUpdate[];
  cents: number;
  target_duration: number;
  interest_rate: number;
  repayment_reminder_sent?: boolean;
};

type LoanStatus =
  | "PENDING"
  | "CASH_SENT"
  | "REPAYMENT_CLAIMED"
  | "LOAN_REPAID"
  ;

type LoanStatusUpdate = StatusUpdate & {
  status: LoanStatus;
};

export type FriendGraphNode = {
  friend_ids: string[];
};

export type ConnectionInvitation = {
  id: string;
  from: string;
  to: string;
};

export type LeaderboardEntry = {
  id: string;
  user_id: string;
  cents: number;
  timestamp: number;
};

export type CashSentLoan = Loan & {
  status:
    | "CASH_SENT"
    | "REPAYMENT_CLAIMED"
    | "LOAN_REPAID"
  ;
};

export function isCashSent(loan: Loan): loan is CashSentLoan {
  for (const update of loan.status_updates) {
    if (update.status === "CASH_SENT") {
      return true;
    }
  }
  return false;
}

export type RepaymentClaimedLoan = Loan & {
  status:
    | "REPAYMENT_CLAIMED"
    | "LOAN_REPAID"
  ;
};

export function isRepaymentClaimed(loan: Loan): loan is RepaymentClaimedLoan {
  for (const update of loan.status_updates) {
    if (update.status === "REPAYMENT_CLAIMED") {
      return true;
    }
  }
  return false;
}

export function applicationTimestamp(loan: Loan): number {
  for (const update of loan.status_updates) {
    if (update.status === "PENDING") {
      return update.timestamp;
    }
  }
  return 0; // XXX: Unreachable.
}

export function cashSentTimestamp(loan: CashSentLoan): number {
  for (const update of loan.status_updates) {
    if (update.status === "CASH_SENT") {
      return update.timestamp;
    }
  }
  return 0; // XXX: Unreachable.
}

export function repaymentClaimedTimestamp(loan: RepaymentClaimedLoan): number {
  for (const update of loan.status_updates) {
    if (update.status === "REPAYMENT_CLAIMED") {
      return update.timestamp;
    }
  }
  return 0; // XXX: Unreachable.
}

export function actualDuration(loan: RepaymentClaimedLoan): number {
  // The actual number of days (in floating-point) that the loan was out for.
  const seconds = repaymentClaimedTimestamp(loan) - cashSentTimestamp(loan);
  const days = seconds / 60 / 60 / 24;
  return days;
}

export function calculateDueBy(startTimestamp: number, days: number): number {
  return startTimestamp + days * 24 * 60 * 60;
}

export function calculateRepayment(cents: number, basisPoints: number, days: number) {
  const years = days / 365.25;
  return Math.round(cents * Math.exp((basisPoints / 10000) * years));
}

export function dueByTimestamp(loan: CashSentLoan): number {
  return calculateDueBy(cashSentTimestamp(loan), loan.target_duration);
}

export function expectedRepayment(loan: CashSentLoan): number {
  const now = Date.now() / 1000;
  const head = isRepaymentClaimed(loan) ? repaymentClaimedTimestamp(loan) : now;
  const seconds = head - cashSentTimestamp(loan);
  const days = seconds / 60 / 60 / 24;
  return calculateRepayment(loan.cents, loan.interest_rate, days);
}

export function totalInterest(loan: CashSentLoan): number {
  return expectedRepayment(loan) - loan.cents;
}

export function borrowerMinDuration(): number {
  return 1;
}

export function borrowerMaxDuration(rep: number): number {
  return 13 + Math.floor(rep / 10);
}

export function borrowerDefaultDuration(rep: number): number {
  return Math.round((borrowerMinDuration() + borrowerMaxDuration(rep)) / 2);
}

export function borrowerMinAmount(): number {
  return 100;
}

export function borrowerMaxAmount(rep: number): number {
  return Math.round(2400 + 10 * rep);
}

export function borrowerDefaultAmount(rep: number): number {
  return Math.round((borrowerMinAmount() + borrowerMaxAmount(rep)) / 2);
}

export function borrowerInterestRate(): number {
  return 0;
}
