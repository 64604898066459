import React from "react";
import { Loan } from "./firestore-types";

import SFactory from "./s-factory";

const S = SFactory("pretty-print");

function breakUp(totalCents: number) {
  const cents = totalCents % 100;
  const dollars = Math.round((totalCents - cents) / 100);
  return [dollars, cents];
}

export function ppAmount(totalCents: number) {
  const [dollars, cents] = breakUp(totalCents);
  return "$" + String(dollars) + "." + String(cents).padStart(2, "0");
}

export function ppInterestRate(basisPoints: number) {
  return `${(basisPoints / 100).toFixed(1)}%`;
}

export function ppTimestamp(timestamp: number) {
  var date = new Date(timestamp * 1000);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var year = date.getFullYear();
  var month = months[date.getMonth()];
  var day = date.getDate();
  /*
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  */
  return `${month} ${day}, ${year}`;
}

const LoanPending = () => <S k="loanStatus_pending">Pending</S>;
const LoanCashSent = () => <S k="loanStatus_cashSent">Active</S>;
const LoanRepaymentClaimed = () => <S k="loanStatus_repaymentClaimed">Repayment pending</S>;
const LoanRepaid = () => <S k="loanStatus_repaid">Repaid</S>;

export function ppLoanStatus(loan: Loan) {
  switch (loan.status) {
  case "PENDING":
    return <LoanPending />;
  case "CASH_SENT":
    return <LoanCashSent />;
  case "REPAYMENT_CLAIMED":
    return <LoanRepaymentClaimed />;
  case "LOAN_REPAID":
    return <LoanRepaid />;
  }
}

type AmountProps = {
  cents: number;
};

export function Amount({cents}: AmountProps) {
  return <span>{ppAmount(cents)}</span>;
}

type DayMonthYearProps = {
  timestamp: number;
};

export function DayMonthYear({timestamp}: DayMonthYearProps) {
  return <span>{ppTimestamp(timestamp)}</span>;
}

type InterestRateProps = {
  basisPoints: number;
};

export function InterestRate({basisPoints}: InterestRateProps) {
  return <span>{ppInterestRate(basisPoints)}</span>;
}
