import React from "react";
import { useLocation, Link } from "react-router-dom";
import { AppContext } from "../app-context";
import { signOut } from "../firebase";
import { isAdmin, FirestoreUser } from "../firestore-types";
import { Language, useLanguageSelection } from "../i18n";
import { showBurgerMenu, hideBurgerMenu } from "../burger-utils";
import { List as ListIcon, X as XIcon } from "react-bootstrap-icons";
import GetCashNow from "./get-cash-now";
import SFactory from "../s-factory";
import { Us, Es } from "react-flags-select";

const S = SFactory("header");

const Icon = () => <Link to="/"><img className="MenshLogo Icon" src="./nav-icon.png" alt="Mensh logo" /></Link>;
const Title = () => <span className="MenshLogo Title"><Link to="/"><S k="title">mensh</S></Link></span>;
const Logo = () => <div className="MenshLogo">
  <Icon />
  <Title />
</div>;

type RepProps = {user: FirestoreUser};
const Rep = ({user}: RepProps) =>
  <div className="RepCell">
    <Link to='/reputation'>
      {user.rep} rep
    </Link>
  </div>;

const Home = () => <div><Link to="/home"><S k="home">Home</S></Link></div>;
const Loans = () => <div><Link to="/loans"><S k="loans">Loans</S></Link></div>;
const Network = () => <div><Link to="/network"><S k="network">Network</S></Link></div>;
const Account = () => <div><Link to="/account"><S k="account">Account</S></Link></div>;
const Admin = () => <div><Link to="/admin">Admin</Link></div>;
const FAQ = () => <div><Link to="/faq"><S k="faq">FAQ</S></Link></div>;
const SignIn = () => <div><Link to="/sign-in"><S k="signIn">Sign In</S></Link></div>;
const CreateAccount = () => <div><Link to="/create-account"><S k="createAccount">Create Account</S></Link></div>;
// eslint-disable-next-line
const SignOut = ({name}: {name: string}) => <div><a href="#" onClick={signOut}><S k="signOut">Sign Out</S> {name}</a></div>;

const iconSize = 30;

type LanguageButtonProps = {
  language: Language;
  setLanguage: (newLanguage: Language) => void;
};
// eslint-disable-next-line
const ES = ({language, setLanguage}: LanguageButtonProps) => <div className="Flag"><a href="javascript: null;" className={language == "es" ? "SelectedLanguage" : ""} onClick={() => setLanguage("es")} ><Es /></a></div>;
// eslint-disable-next-line
const EN = ({language, setLanguage}: LanguageButtonProps) => <div className="Flag"><a href="javascript: null;" className={language == "en" ? "SelectedLanguage" : ""} onClick={() => setLanguage("en")} ><Us /></a></div>;

type HeaderProps = {
  context: AppContext;
};

export default function Header({context}: HeaderProps) {
  const location = useLocation();
  const [language, setLanguage] = useLanguageSelection();

  React.useEffect(() => hideBurgerMenu(), [location]);

  const showGetCashNow = !context.signedIn || !["/", "/create-account", "/new-loan"].includes(location.pathname);

  return <>
    <header className="DesktopHeader">
      <div><Logo /></div>
      <nav>
        <ES {...{language, setLanguage}} />
        <EN {...{language, setLanguage}} />
        {context.signedIn && <>
          <Rep user={context.firestoreUser} />
          <Home /> <Loans /> <Network /> <Account />
          {isAdmin(context.firebaseUser.email) && <Admin />}
        </>}
        <FAQ />
        {!context.signedIn && <>
          <CreateAccount /> <SignIn />
        </>}
        {context.signedIn && <SignOut name={context.firestoreUser.first_name} />}
        {showGetCashNow && <GetCashNow />}
      </nav>
    </header>
    <header className="MobileHeader">
      <div><Logo /></div>
      <div className="MobileNavButtons">
        <EN {...{language, setLanguage}} />
        <ES {...{language, setLanguage}} />
        <ListIcon onClick={showBurgerMenu} size={iconSize} />
      </div>
      <div className="BurgerMenuBackdrop" id="BurgerMenuBackdrop" onClick={hideBurgerMenu} />
      <nav className="BurgerMenu" id="BurgerMenu">
        <XIcon className="CloseButton" size={iconSize} onClick={hideBurgerMenu} />
        <GetCashNow />
        {context.signedIn && <>
          <Rep user={context.firestoreUser} />
          <Home /> <Loans /> <Network /> <Account />
          {isAdmin(context.firebaseUser.email) && <Admin />}
        </>}
        <FAQ />
        {!context.signedIn && <>
          <CreateAccount /> <SignIn />
        </>}
        {context.signedIn && <SignOut name={context.firestoreUser.first_name} />}
      </nav>
    </header>
  </>;
}
