import SFactory from "../s-factory";

const S = SFactory("loading-screen");

export default function LoadingScreen() {
  return (
    <div className="LoadingScreen">
      <div><S k="title">Loading…</S></div>
    </div>
  );
}
