import React from "react";
import { Loan, applicationTimestamp, isCashSent, totalInterest, dueByTimestamp } from "../firestore-types";
import { Amount, DayMonthYear, InterestRate } from "../pretty-print";
import AsyncButton from "./async-button";
import { sendLoanRepaymentReminder } from "../cloud-functions";

type LoanDetailsProps = {
  loan: Loan;
};

export default function LoanDetails({loan}: LoanDetailsProps) {
  return <div className="LoanCell">
    <h2 className="LoanCellTitle">
      <Amount cents={loan.cents}/> (<DayMonthYear timestamp={applicationTimestamp(loan)} />)
    </h2>
    <ul>
      <li>User: {loan.user_id}</li>
      <li>Status: {loan.status}</li>
      <li>Duration: {loan.target_duration} days</li>
      <li>Interest rate: <InterestRate basisPoints={loan.interest_rate} /></li>
      {isCashSent(loan) && <>
        <li>Due by: <DayMonthYear timestamp={dueByTimestamp(loan)} /></li>
        <li>Repayment reminder sent: {loan.repayment_reminder_sent ? "✅" : "NO"}</li>
        <li>Total interest: <Amount cents={totalInterest(loan)} /></li>
      </>}
    </ul>
    {loan.status === "CASH_SENT" &&
      <AsyncButton disabled={loan.repayment_reminder_sent} onClick={() => sendLoanRepaymentReminder({loan_id: loan.id})}>
        Send Repayment Reminder
      </AsyncButton>
    }
  </div>;
}
