import React from "react";
import { Loan } from "../firestore-types";
import { ppAmount, ppInterestRate } from "../pretty-print";
import SFactory from "../s-factory";

const S = SFactory("loan-details-component");

type LoanDetailsProps = {
  loan: Loan;
};

export function LoanDetails({loan}: LoanDetailsProps) {
  return <ul>
    <li><S k="userIDLabel">User ID</S>: {loan.user_id}</li>
    <li><S k="loanIDLabel">Loan ID</S>: {loan.id}</li>
    <li><S k="loanStatusLabel">Status</S>: {loan.status}</li>
    <li><S k="amountLabel">Amount</S>: {ppAmount(loan.cents)}</li>
    <li><S k="durationLabel">Duration</S>: {loan.target_duration}</li>
    <li><S k="interestRateLabel">Interest rate</S>: {ppInterestRate(loan.interest_rate)}</li>
  </ul>;
}
