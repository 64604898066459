import React from "react";
import { LeaderboardEntry } from "../firestore-types";
import { ppAmount, ppTimestamp } from "../pretty-print";
import SFactory from "../s-factory";

const S = SFactory("leaderboard-table");

type RowProps = {
  entry: LeaderboardEntry;
  extraCell: React.FunctionComponent<any> | any;
};

function Row({entry, extraCell}: RowProps) {
  const ExtraCell = extraCell;
  return <tr>
    <td>{entry.user_id}</td>
    <td>{ppAmount(entry.cents)}</td>
    <td>{ppTimestamp(entry.timestamp)}</td>
    {extraCell && <td><ExtraCell {...{entry}} /></td>}
  </tr>;
}

type LeaderboardProps = {
  entries: LeaderboardEntry[];
  extraCell?: React.FunctionComponent<any>;
  extraColumnTitle?: string;
};

export function Leaderboard({entries, extraCell, extraColumnTitle}: LeaderboardProps) {
  if (extraColumnTitle === undefined) {
    extraColumnTitle = "Extra";
  }
  return <table>
    <thead>
      <tr>
        <th><S k="userIDColumnHeader">User</S></th>
        <th><S k="amountColumnHeader">Amount</S></th>
        <th><S k="dateColumnHeader">Date</S></th>
        {extraCell && <th>{extraColumnTitle}</th>}
      </tr>
    </thead>
    <tbody>
    {entries.map(entry => (
      <Row key={entry.id} {...{entry, extraCell}} />
    ))}
    </tbody>
  </table>;
}
