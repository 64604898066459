import React from "react";
import AdminInstructions from "../components/admin-instructions";
import { Navigate, useParams, useLocation } from "react-router-dom";
import { useLoan, useAnyFirestoreUser } from "../firebase";
import { useAppContext } from "../app-context";
import { LoanDetails } from "../components/loan-details";
import { isAdmin, Loan, FirestoreUser, expectedRepayment, isRepaymentClaimed } from "../firestore-types";
import { ppAmount } from "../pretty-print";
import { loanCashSent, loanRepaymentReceived } from "../cloud-functions";
import ErrorScreen from "../components/error-screen";

type ContentsProps = {
  loan: Loan;
  firestoreUser: FirestoreUser;
};

function Contents({loan, firestoreUser}: ContentsProps) {
  async function onLoanCashSent() {
    try {
      console.log("Loan cash sent (according to you!)");
      console.log("Updating loan status in database...");
      await loanCashSent({loan_id: loan.id});
      console.log("SUCCESS updating loan status to indicate cash sent!");
    } catch (e) {
      console.error("ERROR updating loan status in database!");
    }
  }

  async function onLoanRepaymentReceived() {
    try {
      console.log("Loan repayment received on Venmo (according to you!)");
      console.log("Updating loan status in database...");
      await loanRepaymentReceived({loan_id: loan.id});
      console.log("SUCCESS updating loan status to indicate repayment received!");
    } catch (e) {
      console.error("ERROR updating loan status in database!");
    }
  }

  return <>
    <AdminInstructions />
    <h1>Loan Details (Admin)</h1>
    <LoanDetails {...{loan}} />
    {loan.status === "PENDING" && <p>
      This loan is pending. If you want to approve the loan, send {ppAmount(loan.cents)} to Venmo username "{firestoreUser.venmo_username}", then click this button:
      <button onClick={onLoanCashSent}>Loan Cash Sent!</button>
    </p>}
    {isRepaymentClaimed(loan) && loan.status === "REPAYMENT_CLAIMED" && <p>
      The user has claimed that they have returned the cash from this loan, including interest, via Venmo. You need to confirm that this is the case. If you received {ppAmount(expectedRepayment(loan))} from Venmo username "{firestoreUser.venmo_username}", then click this button:
      <button onClick={onLoanRepaymentReceived}>Loan Repayment Received!</button>
    </p>}
  </>;
}

export default function AdminLoanDetails() {
  const location = useLocation();
  const context = useAppContext();

  const { id } = useParams() as {id: string};
  const loan = useLoan(id);
  const firestoreUser = useAnyFirestoreUser(loan?.user_id);

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (!isAdmin(context.firebaseUser.email)) {
    return <ErrorScreen message="Not authorized!" />;
  } else if (!loan || !firestoreUser || firestoreUser === "FIRESTORE_USER_LOADING" ||
    /*XXX*/ firestoreUser === "FIRESTORE_USER_ERROR") {
    return <h1>Loading...</h1>;
  } else {
    return <Contents {...{loan, firestoreUser}} />;
  }
}
