import * as React from "react";
import { Navigate, Link, useLocation, useNavigate } from "react-router-dom";
import { submitBorrowerApplication } from "../cloud-functions";
import { useAppContext } from "../app-context";
import SFactory from "../s-factory";
import Compulsory from "../components/compulsory";

const S = SFactory("/create-account");

function Content() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [birthday, setBirthday] = React.useState("1990-01-01");
  const [venmoUsername, setVenmoUsername] = React.useState("");
  const [paypalUsername, setPaypalUsername] = React.useState("");
  const [zelleUsername, setZelleUsername] = React.useState("");
  const [cashappUsername, setCashappUsername] = React.useState("");
  const [paymentType, setPaymentType] = React.useState<"VENMO" | "PAYPAL" | "ZELLE" | "CASHAPP">("VENMO");

  const [iAmAnAmerican, setIAmAnAmerican] = React.useState(false);
  const [iAcceptTheTOS, setIAcceptTheTOS] = React.useState(false);
  const [iAcceptThePP, setIAcceptThePP] = React.useState(false);

  const onPaymentTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setPaymentType(e.target.value as any); // XXX
    }
  };

  // IMPORTANT (GH #20): We always use all-lowercase versions of email addresses.
  const actualEmail = email.trim().toLowerCase();

  const actualFirstName = firstName.trim();
  const actualLastName = lastName.trim();
  const formIsValid = 
    actualEmail !== "" &&
    password !== "" &&
    (
      (paymentType === "VENMO" && venmoUsername !== "") ||
      (paymentType === "PAYPAL" && paypalUsername !== "") ||
      (paymentType === "ZELLE" && zelleUsername !== "") ||
      (paymentType === "CASHAPP" && cashappUsername !== "")
    ) &&
    actualFirstName !== "" &&
    actualLastName !== "" &&
    phoneNumber !== "" &&
    iAmAnAmerican &&
    iAcceptTheTOS &&
    iAcceptThePP &&
    true
  ;
  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (disabled) {
      console.error("Attempted to submit borrower application while disabled; ignoring");
      return;
    }

    setDisabled(true);

    console.log("Submitting borrower application…");
    submitBorrowerApplication({
      email: actualEmail,
      password,
      first_name: actualFirstName,
      last_name: actualLastName,
      phone_number: phoneNumber,
      birthday,
      venmo_username: paymentType === "VENMO" ? venmoUsername : undefined,
      paypal_username: paymentType === "PAYPAL" ? paypalUsername : undefined,
      zelle_username: paymentType === "ZELLE" ? zelleUsername : undefined,
      cashapp_username: paymentType === "CASHAPP" ? cashappUsername : undefined,
    })
    .then(() => {
      console.log("Borrower application submitted");
      navigate('/home');
    })
    .catch(e => {
      console.error("Error submitting borrower application", e);
      alert(`Could not submit application, please try again: ${e}`);
      setDisabled(false);
    });
  }

  return (
    <>
      <h1><S k="title">Create Account</S></h1>
      <form {...{onSubmit}}>
        <label><S k="emailAddressLabel">Email address</S><Compulsory /><br/>
          <input {...{disabled}} type="text" value={email} onChange={e => setEmail(e.target.value)} />
        </label>
        <label><S k="passwordLabel">Password</S><Compulsory /><br/>
          <input {...{disabled}} type="password" autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} />
        </label>
        <label><S k="firstNameLabel">First name</S><Compulsory /><br/>
          <input {...{disabled}} type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
        </label>
        <label><S k="lastNameLabel">Last name</S><Compulsory /><br/>
          <input {...{disabled}} type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
        </label>
        <label><S k="phoneNumberLabel">Phone number</S><Compulsory /><br/>
          <input {...{disabled}} type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
        </label>
        <label><S k="birthdayLabel">Birthday</S><Compulsory /><br/>
          <input {...{disabled}} type="date" value={birthday} onChange={e => setBirthday(e.target.value)} />
        </label>
        <label>
          Account type<br />
          <label>
            <input type="radio" id="contactChoice1" name="paymentType" value="VENMO" checked={paymentType === "VENMO"} onChange={onPaymentTypeChange} />
            Venmo
          </label>
          <label>
            <input type="radio" id="contactChoice2" name="paymentType" value="ZELLE" checked={paymentType === "ZELLE"} onChange={onPaymentTypeChange} />
            Zelle
          </label>
          <label>
            <input type="radio" id="contactChoice3" name="paymentType" value="CASHAPP" checked={paymentType === "CASHAPP"} onChange={onPaymentTypeChange} />
            Cash App
          </label>
        </label>
        {paymentType === "VENMO" &&
          <label><S k="venmoLabel">Venmo account name</S><Compulsory /><br/>
            <input {...{disabled}} type="text" value={venmoUsername} onChange={e => setVenmoUsername(e.target.value)} />
            <p><S k="venmoWarning">Please make sure the email or phone you use for mensh matches that on your Venmo account. If you use your phone or email for your Venmo account, please retype it here.</S></p>
          </label>
        }{paymentType === "PAYPAL" &&
          <label><S k="paypalLabel">PayPal account name</S><Compulsory /><br/>
            <input {...{disabled}} type="text" value={paypalUsername} onChange={e => setPaypalUsername(e.target.value)} />
            <p><S k="paypalWarning">Please make sure the email or phone you use for mensh matches that on your PayPal account. If you use your phone or email for your PayPal account, please retype it here.</S></p>
          </label>
        }{paymentType === "ZELLE" &&
          <label><S k="zelleLabel">Zelle account name</S><Compulsory /><br/>
            <input {...{disabled}} type="text" value={zelleUsername} onChange={e => setZelleUsername(e.target.value)} />
            <p><S k="zelleWarning">Please make sure the email or phone you use for mensh matches that on your Zelle account. If you use your phone or email for your Zelle account, please retype it here.</S></p>
          </label>
        }{paymentType === "CASHAPP" &&
          <label><S k="cashappLabel">Cash App account name</S><Compulsory /><br/>
            <input {...{disabled}} type="text" value={cashappUsername} onChange={e => setCashappUsername(e.target.value)} />
            <p><S k="cashappWarning">Please make sure the email or phone you use for mensh matches that on your Cash App account. If you use your phone or email for your Cash App account, please retype it here.</S></p>
          </label>
        }
        <label>
          <input {...{disabled}} type="checkbox" checked={iAmAnAmerican} onChange={e => setIAmAnAmerican(e.target.checked)} />
          <S k="iAmInTheUSA">I am located in the USA.</S>
        </label>
        <label>
          <input {...{disabled}} type="checkbox" checked={iAcceptTheTOS} onChange={e => setIAcceptTheTOS(e.target.checked)} />
          I have read and accept the <Link to="/tos">Terms of Service</Link>.
        </label>
        <label>
          <input {...{disabled}} type="checkbox" checked={iAcceptThePP} onChange={e => setIAcceptThePP(e.target.checked)} />
          <S k="iAcceptThePP">I have read and accept the <Link to="/privacy">Privacy Policy</Link>.</S>
        </label>
        <button type="submit" disabled={disabled || !formIsValid}>
          {!disabled
            ? <S k="submitApplication">Submit Application</S>
            : <S k="pleaseWait">Please wait…</S>}
        </button>
      </form>
    </>
  );
}

export default function CreateAccount() {
  const location = useLocation();
  const context = useAppContext();
  const redirectURL = (location.state as any)?.redirectURL || "/home";

  if (context.signedIn) {
    return <Navigate replace to={redirectURL} />;
  } else {
    return <Content />;
  }
}
