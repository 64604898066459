import { User } from "firebase/auth";
import { FirestoreUser } from "./firestore-types";
import { useOutletContext } from "react-router-dom";

type NotSignedInContext = {
  signedIn: false;
  pending: false;
  firebaseUser: undefined;
  firestoreUser: undefined;
};

type PendingContext = {
  signedIn: true;
  pending: true;
  firebaseUser: User;
  firestoreUser: FirestoreUser;
};

type SignedInContext = {
  signedIn: true;
  pending: false;
  firebaseUser: User;
  firestoreUser: FirestoreUser;
};

export type AppContext = 
  | NotSignedInContext
  | PendingContext
  | SignedInContext
;

export function useAppContext() {
  return useOutletContext<AppContext>();
}
