import React from "react";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import SFactory from "../s-factory";

const S = SFactory("/forgot-password");

export default function ForgotPassword() {

  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [done, setDone] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState("");

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      console.log(`Sending password reset email for ${email}`);
      setDisabled(true);

      // IMPORTANT (GH #20): We always use all-lowercase versions of email addresses.
      const actualEmail = email.trim().toLowerCase();

      await sendPasswordResetEmail(auth, actualEmail);
      setDone(true);
      console.log(`Successfully sent password reset email for ${email}`);
    } catch (e) {
      alert(`Error sending password reset email for ${email}! Please try again.`);
      console.error(`Error sending password reset email for ${email}!`, e);
      setDisabled(false);
    }
  }

  return (
    <>
      <h1><S k="title">Forgot Password</S></h1>
      <p><S k="enterYourEmailAddress">Enter your email address to receive a password reset email.</S></p>
      <form {...{onSubmit}}>
        <label><S k="emailAddressLabel">Email address</S><br/>
          <input {...{disabled}} type="text" autoComplete="username" value={email} onChange={e => setEmail(e.target.value)} />
        </label>
        <button {...{disabled}} type="submit"><S k="submit">Reset Password</S></button>
      </form>
      {done && <p><S k="checkYourInbox">Password reset email sent! Please check your inbox.</S></p>}
    </>
  );
}
