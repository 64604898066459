import React from "react";
import { collection, query } from "firebase/firestore";
import { db, useQuery } from "../firebase";
import { Leaderboard } from "../components/leaderboard";
import { LeaderboardEntry } from "../firestore-types";
import LoadingScreen from "../components/loading-screen";
import SFactory from "../s-factory";

const S = SFactory("/leaderboard");

type ContentsProps = {
  entries: LeaderboardEntry[];
};

function Contents({entries}: ContentsProps) {
  return <>
    <h1><S k="title">Leaderboard</S></h1>
    <p><S k="ifYouDefault">If you default, your name will appear here.</S></p>
    <Leaderboard {...{entries}} />
  </>;
}

export default function History() {
  const q = React.useMemo(() => query(collection(db, "leaderboard")), []);
  const entries = useQuery<LeaderboardEntry>(q);

  if (entries.status !== "Snapshot") {
    return <LoadingScreen />;
  } else {
    return <Contents entries={entries.value} />;
  }
}
