import {functions} from "./firebase";
import {httpsCallable, HttpsCallableResult} from "firebase/functions";
import * as API from "./function-api";

// User methods

export const submitBorrowerApplication: (args: API.SubmitBorrowerApplicationArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "submitBorrowerApplication");

export const resendEmailAddressVerificationEmail: (args: API.ResendEmailAddressVerificationEmailArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "resendEmailAddressVerificationEmail");

export const sendConnectionInvitation: (args: API.SendConnectionInvitationArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "sendConnectionInvitation");

export const cancelConnectionInvitation: (args: API.CancelConnectionInvitationArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "cancelConnectionInvitation");

export const acceptConnectionInvitation: (args: API.AcceptConnectionInvitationArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "acceptConnectionInvitation");

export const removeConnection: (args: API.SendConnectionInvitationArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "removeConnection");

// Admin methods

export const loanCashSent: (args: API.LoanCashSentArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "loanCashSent");

export const sendLoanRepaymentReminder: (args: API.SendLoanRepaymentReminderArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "sendLoanRepaymentReminder");

export const loanRepaymentReceived: (args: API.LoanRepaymentReceivedArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "loanRepaymentReceived");

export const approveUser: (args: API.ApproveUserArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "approveUser");

export const oneOff: (args: API.OneOffArgs) => Promise<HttpsCallableResult<void>> = httpsCallable(functions, "oneOff");
