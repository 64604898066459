import ReactDOM from "react-dom/client";
import {
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";
import App from "./App";
import './i18n';

import Landing from "./routes/landing";
import Home from "./routes/home";
import NewLoan from "./routes/new-loan";
import RepayLoan from "./routes/repay-loan";
import Network from "./routes/network";
import Loans from "./routes/loans";
import Reputation from "./routes/reputation";
import LoanDetails from "./routes/loan-details";
import Account from "./routes/account";
import AddConnection from "./routes/add-connection";
import Leaderboard from "./routes/leaderboard";
import SignIn from "./routes/sign-in";
import ForgotPassword from "./routes/forgot-password";
import CreateAccount from "./routes/create-account";
import Contact from "./routes/contact";
import FAQ from "./routes/faq";
import TermsOfService from "./routes/tos";
import CookiePolicy from "./routes/cookies";
import PrivacyPolicy from "./routes/privacy";
import Admin from "./routes/admin";
import AdminDueLoans from "./routes/admin-due-loans";
import AdminLoanDetails from "./routes/admin-loan-details";
import AdminUserDetails from "./routes/admin-user-details";
import * as R from "./routing";

const root = ReactDOM.createRoot(
  document.getElementById("root") as any
);

root.render(
  <HashRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path={R.landing.path} element={<Landing />} />
        <Route path={R.home.path} element={<Home />} />
        <Route path={R.newLoan.path} element={<NewLoan />} />
        <Route path={R.repayLoan.path} element={<RepayLoan />} />
        <Route path={R.network.path} element={<Network />} />
        <Route path={R.loans.path} element={<Loans />} />
        <Route path={R.loanDetails.path} element={<LoanDetails />} />
        <Route path={R.reputation.path} element={<Reputation />} />
        <Route path={R.account.path} element={<Account />} />
        <Route path={R.addConnection.path} element={<AddConnection />} />
        <Route path={R.leaderboard.path} element={<Leaderboard />} />
        <Route path={R.signIn.path} element={<SignIn />} />
        <Route path={R.forgotPassword.path} element={<ForgotPassword />} />
        <Route path={R.createAccount.path} element={<CreateAccount />} />
        <Route path={R.contact.path} element={<Contact />} />
        <Route path={R.faq.path} element={<FAQ />} />
        <Route path={R.tos.path} element={<TermsOfService />} />
        <Route path={R.cookies.path} element={<CookiePolicy />} />
        <Route path={R.privacy.path} element={<PrivacyPolicy />} />
        <Route path={R.admin.path} element={<Admin />} />
        <Route path={R.adminDueLoans.path} element={<AdminDueLoans />} />
        <Route path={R.adminLoanDetails.path} element={<AdminLoanDetails />} />
        <Route path={R.adminUserDetails.path} element={<AdminUserDetails />} />
      </Route>
    </Routes>
  </HashRouter>
);

/*
// XXX: Incorporate strict stuff from here?

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
*/
