import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { db, FirebaseUser, useQuery } from "../firebase";
import { FirestoreUser, Loan, borrowerMaxAmount, borrowerMaxDuration, borrowerInterestRate, applicationTimestamp } from "../firestore-types";
import { query, collection, where } from "firebase/firestore";
import { useAppContext } from "../app-context";
import { Amount, InterestRate } from "../pretty-print";
import SFactory from "../s-factory";
import GetCashNow from "../components/get-cash-now";
import LoanCell from "../components/loan-cell";

const S = SFactory("/home");

type ContentsProps = {
  firebaseUser: FirebaseUser;
  firestoreUser: FirestoreUser;
};

function Contents({firebaseUser, firestoreUser}: ContentsProps) {
  const email = firebaseUser.email;
  const q = React.useMemo(() => query(
    collection(db, "loans"),
    where("user_id", "==", email),
    where("status", "!=", "LOAN_REPAID"),
  ), [email]);
  const loans = useQuery<Loan>(q);
  return <>
    <h1><S k="welcome">Welcome</S>, {firestoreUser.first_name}</h1>
    <div className="CenteredStack">
      <div className="StackedBoxes">
        <div>
          <div className="LabelValueBox_Label"><S k="maxLoanSizeLabel">Your maximum loan size</S></div>
          <div className="LabelValueBox_Value"><Amount cents={borrowerMaxAmount(firestoreUser.rep)} /></div>
        </div>
        <div>
          <div className="LabelValueBox_Label"><S k="maxLoanDurationLabel">Your maximum loan duration</S></div>
          <div className="LabelValueBox_Value">{borrowerMaxDuration(firestoreUser.rep)} days</div>
        </div>
        <div>
          <div className="LabelValueBox_Label"><S k="interestRateLabel">Your interest rate</S></div>
          <div className="LabelValueBox_Value"><InterestRate basisPoints={borrowerInterestRate()} /></div>
        </div>
      </div>
      <GetCashNow />
    </div>
    {loans.status === "Snapshot" && <>
    {loans.value.length > 0 && <>
      <h1><S k="activeLoansTitle">Your Active Loans</S></h1>
      <div className="StackedBoxes">
        {loans.value
          .sort((loan1, loan2) => applicationTimestamp(loan2) - applicationTimestamp(loan1))
          .map(loan => <LoanCell {...{loan}} key={loan.id} />)}
      </div>
    </>}</>}
  </>;
}

export default function Home() {
  const location = useLocation();
  const context = useAppContext();

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else {
    return <Contents firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  }
}
