type Route = {
  path: string;
  title: string;
  pathRegex?: RegExp;
};

export const account: Route = {
  path: 'account',
  title: 'Account',
};

export const addConnection: Route = {
  path: 'add-connection',
  title: 'Add Connection',
};

export const admin: Route = {
  path: 'admin',
  title: 'Admin',
};

export const adminDueLoans: Route = {
  path: 'admin/due-loans',
  title: 'Admin Due Loans',
};

export const adminLoanDetails: Route = {
  path: 'admin/loan/:id',
  pathRegex: /^admin\/loan\/[0-9a-zA-Z-]+$/,
  title: 'Admin Loan Details',
};

export const adminUserDetails: Route = {
  path: 'admin/user/:id',
  pathRegex: /^admin\/user\/[0-9a-zA-Z@\\.-]+$/,
  title: 'Admin User Details',
};

export const contact: Route = {
  path: 'contact',
  title: 'Contact',
};

export const cookies: Route = {
  path: 'cookies',
  title: 'Cookie Policy',
};

export const createAccount: Route = {
  path: 'create-account',
  title: 'Create Account',
};

export const faq: Route = {
  path: 'faq',
  title: 'FAQ',
};

export const forgotPassword: Route = {
  path: 'forgot-password',
  title: 'Forgot Password',
};

export const home: Route = {
  path: 'home',
  title: 'Dashboard',
};

export const landing: Route = {
  path: '',
  title: 'Home',
};

export const leaderboard: Route = {
  path: 'leaderboard',
  title: 'Leaderboard',
};

export const loanDetails: Route = {
  path: 'loan-details/:id',
  pathRegex: /^loan-details\/[0-9a-zA-Z-]+$/,
  title: 'Loan Details',
};

export const loans: Route = {
  path: 'loans',
  title: 'Loans',
};

export const reputation: Route = {
  path: 'reputation',
  title: 'Reputation',
};

export const network: Route = {
  path: 'network',
  title: 'Network',
};

export const newLoan: Route = {
  path: 'new-loan',
  title: 'New Loan',
};

export const privacy: Route = {
  path: 'privacy',
  title: 'Privacy Policy',
};

export const repayLoan: Route = {
  path: 'repay-loan/:id',
  pathRegex: /^repay-loan\/[0-9a-zA-Z-]+$/,
  title: 'Repay Loan',
};

export const signIn: Route = {
  path: 'sign-in',
  title: 'Sign In',
};

export const tos: Route = {
  path: 'tos',
  title: 'Terms of Service',
};

export const ROUTES = [
  account,
  addConnection,
  admin,
  adminDueLoans,
  adminLoanDetails,
  adminUserDetails,
  contact,
  cookies,
  createAccount,
  faq,
  forgotPassword,
  home,
  landing,
  leaderboard,
  loanDetails,
  loans,
  reputation,
  network,
  newLoan,
  privacy,
  repayLoan,
  signIn,
  tos,
];

export function lookUpRoute(pathname: string): Route {
  for (let route of ROUTES) {
    if (route.pathRegex) {
      if (pathname.match(route.pathRegex)) {
        return route;
      }
    } else {
      if (pathname === route.path) {
        return route;
      }
    }
  }
  throw new Error(`Unknown route: ${pathname} -- did you remember to add your route to ROUTES in routing.ts??`);
}
