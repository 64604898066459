import React from "react";
import { useAppContext } from "../app-context";
import AdminInstructions from "../components/admin-instructions";
import { Navigate, useParams, useLocation } from "react-router-dom";
import { useAnyFirestoreUser } from "../firebase";
import { isAdmin, FirestoreUser } from "../firestore-types";
import { approveUser } from "../cloud-functions";
import ErrorScreen from "../components/error-screen";

type ContentsProps = {
  firestoreUser: FirestoreUser;
};

function Contents({firestoreUser}: ContentsProps) {
  async function onApproveUser() {
    try {
      console.log("Approving user...");
      await approveUser({user_id: firestoreUser.email});
      console.log("SUCCESS approving user!");
    } catch (e) {
      console.error("ERROR approving user!", e);
    }
  }

  const firestoreURL = `https://console.firebase.google.com/u/0/project/mensh-app/firestore/data/~2Fusers~2F${firestoreUser.email}`;

  return <>
    <AdminInstructions />
    <h1>User Details (Admin)</h1>
    <p><a href={firestoreURL}>{firestoreURL}</a></p>
    {firestoreUser.borrower_status === "PENDING" && <p>
      This user's application to Mensh is pending. If you want to approve the user, click this button:
      <button onClick={onApproveUser}>Approve User</button>
    </p>}
  </>;
}

export default function AdminLoanDetails() {
  const location = useLocation();
  const context = useAppContext();

  const { id } = useParams() as {id: string};
  const firestoreUser = useAnyFirestoreUser(id);

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (!isAdmin(context.firebaseUser.email)) {
    return <ErrorScreen message="Not authorized!" />;
  } else if (!firestoreUser || firestoreUser === "FIRESTORE_USER_LOADING" ||
    /*XXX*/ firestoreUser === "FIRESTORE_USER_ERROR") {
    return <h1>Loading...</h1>;
  } else {
    return <Contents {...{firestoreUser}} />;
  }
}
