import { Link } from "react-router-dom";
import SFactory from "../s-factory";

const S = SFactory("/faq");

export default function FAQ() {
  return (
    <>
      <h1><S k="title">FAQ</S></h1>
      <h2><S k="Whatmyreputation?_question">What is my “reputation”?</S></h2>
      <p><S k="Whatmyreputation?_answer">“Reputation” is a measure of your trustworthiness on mensh. The higher your reputation, (1) the higher your maximum loan, (2) the lower your interest rate, and (3) the longer your maximum term. You begin at 1, which is $25 at 25% for two weeks. Your reputation can increase indefinitely. At very high reputation, the improvements to your loan parameters become smaller, so your practical maximum is $2500 at 4% for 1 year.</S></p>
      <h2><S k="HowdoIincreasemyreputation?_question">How do I increase my reputation?</S></h2>
      <p><S k="HowdoIincreasemyreputation?_answer">(1) Repay your loans on time and max out your loan parameters for the highest boost. For example, if you repay your loan after half your maximum term, you will only receive half the boost as if you wait the maximum term. (2) Add friends. The higher their reputation, the bigger the boost of your reputation for adding them. But only connect with trustworthy friends! If they default, you’ll lose 1/4 of your reputation—likely far more than the boost you received for adding them in the first place.</S></p>
      <h2><S k="WhatifIpaylate?_question">What if I repay late?</S></h2>
      <p><S k="WhatifIpaylate?_answer">You will get an email reminding you to repay at least 10% by your due date. If you do not repay that, your reputation will decrease a little and you will have another week to repay at least 10%, whilst your accumulated interest increases. Your friends will be notified and their reputations will decrease a little as well. If you do not repay again, you will be be given another week and reminded a last time to repay at least 10%, reducing your and your friends’ reputations further and increasing accumulated interest. If you still do not repay, you will be considered in default.</S></p>
      <h2><S k="WhatifIdefault?_question">What if I default?</S></h2>
      <p><S k="WhatifIdefault?_answer">Your reputation will fall to 0 and you will not be able to request more money. Your friends will receive a large penalty to their reputation. You can contact us and make a case to have your reputation restored to 1. But that is it.</S></p>
      <h2><S k="Aretherereallynostringsattached?_question">Are there really no strings attached?</S></h2>
      <p><S k="Aretherereallynostringsattached?_answer">No, there are not! There are no consequences outside of mensh. We at mensh believe that social carrots and sticks are all that people need to be good borrowers—which is the way credit has worked in communities since before the dawn of history. If you do not repay, your friends will likely be disappointed. If you’re someone that does not tend to uphold your promises, you are likely not to have real-life friends that trust you enough to connect with you on mensh.</S></p>
      <h2><S k="HowdoIaddfriends?_question">How do I add friends?</S></h2>
      <p><S k="HowdoIaddfriends?_answer">Go <Link to="/network">here</Link> and type their email address. They will receive a friend request.</S></p>
      <h2><S k="WhathappenswhenIaddfriends?_question">What happens when I add friends?</S></h2>
      <p><S k="WhathappenswhenIaddfriends?_answer">You both receive a boost to your reputation, proportional to your respective reputations. If your friend’s reputation is much higher than yours, yours will increase much more than your friend’s for connecting.</S></p>
      <h2><S k="Whatifafriendpayslate?_question">What if a friend repays late?</S></h2>
      <p><S k="Whatifafriendpayslate?_answer">You will be notified and your reputation will decrease slightly.</S></p>
      <h2><S k="Whatifafrienddefaults?_question">What if a friend defaults?</S></h2>
      <p><S k="Whatifafrienddefaults?_answer">You’ll lose 1/4 of your reputation. Only connect people you trust!</S></p>
      <h2><S k="HowdoIknowIwillreceivemyloanrequests?_question">How do I know I will receive my loan requests?</S></h2>
      <p><S k="HowdoIknowIwillreceivemyloanrequests?_answer">Although we’re not a bank, we have funding to cover all loan requests for a very long time. If we ever run out of money, you can simply keep your last loan for ever!</S></p>
      <h2><S k="Howismydatasecured?_question">How is my data secured?</S></h2>
      <p><S k="Howismydatasecured?_answer">We use Google’s Firebase to store our data, which fully encrypts all data. To see their data protection policy, please visit https://firebase.google.com/support/privacy.</S></p>
      <h2><S k="Whatdoesmenshmean_question">What does “mensh” mean?</S></h2>
      <p><S k="Whatdoesmenshmean_answer">Its two meanings are what increase your reputation://n In English, it’s slang for “mention”—which is the first step to vouching for your friends and improving your reputation.//n In Yiddish, it means “an honest person”—if you repay your loans honestly, your reputation increases.</S></p>
    </>
  );
}
