import SFactory from "../s-factory";

const S = SFactory("/tos");

export default function TermsOfService() {
  return (<>
    <h1><S k="title">Terms of Service</S></h1>
    <p>These Terms of Service were last updated on 1 September 2022.</p>
    <p>The following terms of service are terms of a legal agreement (the “Agreement”) between you (“you”, “your”, or “user”) and mensh, its subsidiaries, affiliates, agents and assigns (“mensh”, “we”, “us”, or “our”) which sets forth the terms and conditions for your use of mensh’s website, mensh.app. By accessing, browsing and/or using mensh.app or the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement and to comply with all applicable laws and regulations.</p>
    <p>The terms and conditions of this Agreement form an essential basis of the bargain between you and mensh, and this Agreement governs your use of mensh.app and the Services.</p>

    <h2>Acceptance of Agreement</h2>
    <p>Please carefully review this Agreement before using mensh.app or the Services, or accessing any data thereon. If you do not agree to these terms, you may not access or use mensh.app or the Services. To use mensh.app or the Services and to accept the Agreement, you must be 1) a legal resident of the United States of America, 2) of legal age to form a binding contract with mensh, 3) not prohibited by law from mensh.app or the Services.</p>

    <h2>Modification of this Agreement</h2>
    <p>mensh reserves the right to amend this Agreement at any time and will notify you of any such changes by posting the revised Agreement on its website, mensh.app. You should check this Agreement on mensh.app periodically for changes. All changes shall be effective upon posting. We will date the terms with the last day of revision. Your continued use of mensh.app or the Services after any change to this Agreement constitutes your agreement to be bound by any such changes. mensh may terminate, suspend, change, or restrict access to all or any part of mensh.app or the Services without notice or liability.</p>

    <h2>Privacy Policy</h2>
    <p>mensh maintains a Privacy Policy, and it details how we handle and protect data. We fully incorporate our Privacy Policy into this Agreement. Note that we reserve the right to update the Privacy Policy at our discretion, and that any changes made to our Privacy Policy are effective when the updates are live on mensh.app.</p>

    <h2>User Information Accuracy and Updates</h2>
    <p>To access mensh’s Services, you must create a user account with mensh. This process will include creation of a Login ID and password to access the website and the Services. When you sign up for a user account, you agree to provide accurate, current and complete information—such as your name, mailing address, and email address –as may be prompted by any registration forms available through the website, in connection with the Services or as otherwise requested by mensh for such information (“User Information”). You further represent that you are a legal owner of, and that you are authorized to provide us with, all User Information and other information necessary to facilitate your use of the Mobile App and Services.In order to use certain Services, mensh may be required to verify your identity. You authorize us to make any inquiries we consider necessary to validate your identity. If you do not respond to such inquiries or we cannot verify your identity, we can refuse to allow you to use our Services.Should any of your User Information change, you agree that you will update this information as soon as possible. Should you believe or have reason to believe that any of your User Information, including your Login ID and/or password, has been compromised, or that another person is accessing your user account through some other means, you agree to notify us as soon as possible at hi@mensh.app.</p>

    <h2>Overview of Services</h2>
    <p>mensh offers a variety of services and features collectively referred to in this Agreement as the “Services.” These Services include: providing you, via your Venmo account, with the option to receive money; an algorithm that calculates, from your transaction history and network connections, the parameters you may use to request money in the future; and the option to send money to mensh via your venmo account. The money you receive does not constitute a “loan” because there is nothing mensh can do to enforce its “repayment” outside of the app; inside the app, all mensh does is restrict your ability and that of those users (“friends”) you are connected to to request more if you do not pay it. If you do not make the payment with the suggested interest and within the suggested time frame, and/or do not make the minimum payments suggested, you will not suffer any legal consequences: all that will occur is that, if you have entered into any connections with other friends, their ability to request money will suffer as a result of you not making the suggested payment (measured by the concept of “reputation”, which is a numerical value that is a proxy for your trustworthiness). You and the friends you have connected with will be warned by email when someone they are connected with has either not made a payment and is close to defaulting or has already defaulted. By creating and account on mensh and using mensh, if you do not make the suggested payments which consist of the money mensh gave you in the first place plus an interest determined by the interest rate which is a consequence of your transaction history, you are explicitly agreeing to your friends’ reputation changing as a result of your repayment success on mensh. Please view the Frequently Asked Questions for more information.</p> 

    <h2>Consent to Electronic Communications and doing Business Electronically</h2>
    <p>Communications to Be Provided in Electronic Form: By choosing to use the website or the Services, you will receive from time-to-time disclosures, notices, documents, and any other communications about our Services, the website from mensh (“Communications”). We can only give you the benefits of our Services by conducting business through the Internet, and therefore we need you to consent to receiving Communications electronically. This section informs you of your rights when receiving electronic Communications from us. We may discontinue electronic provision of Communications at any time in our sole discretion. By creating an account, you are opting in to receiving promotional emails from us. You can always unsubscribe by contacting us.</p>

    <h2>Communications in Writing</h2>
    <p>By accepting to this Agreement, you agree that electronic Communications shall be considered “in writing” and have the same meaning and effect as if provided in paper form, unless you have withdrawn your consent to receive Communications electronically as stated below. You agree that we have no obligation to provide you Communications in paper format, although we reserve the right to do so at any time.</p>

    <h2>SMS Messaging and Telephone Calls</h2>
    <p>You consent to receive SMS messages (including text messages), and telephone calls (including prerecorded and artificial voice and autodialed) from us, our agents, representatives, affiliates or anyone calling on our behalf at the specific number(s) you have provided to us, with service-related information such as alerts, or questions about your use of the Services and/or the website. You certify, warrant and represent that the telephone number you have provided to us is your contact number and not someone else’s. You represent that you are permitted to receive calls and text messages at the telephone number you have provided to us. You agree to promptly alert us whenever you stop using a telephone number. mensh and our agents, representatives, affiliates and anyone calling on our behalf may use such means of communication described in this section even if you will incur costs to receive such phone messages, text messages, e-mails or other means. Standard message and data rates may apply to all SMS messages (including text messages). We may modify or terminate our SMS messaging services from time to time, for any reason, and without notice, including the right to terminate SMS messaging with or without notice, without liability to you.</p>

    <h2>Limitations of Use</h2>
    By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:
    <ol>
    <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
    <li>remove any copyright or other proprietary notations from any materials and software on this website;</li>
    <li>transfer the materials to another person or “mirror” the materials on any other server;</li>
    <li>knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service mensh provides;</li>
    <li>use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
    <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
    <li>use this website in conjunction with sending unauthorized advertising or spam;</li>
    <li>harvest, collect, or gather user data without the user’s consent; or</li>
    <li>use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
    </ol>

    <h2>Intellectual Property</h2>
    <p>The intellectual property in the materials contained in this website are owned by or licensed to mensh and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use.</p>
    <p>This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by mensh at any time.</p>

    <h2>Liability</h2>
    <p>Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, mensh makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.</p>
    <p>In no event shall mensh or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if mensh or an authorized representative has been notified, orally or in writing, of the possibility of such damage.</p>
    <p>In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity, or otherwise.</p>
    <p>Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

    <h2>Indemnification</h2>
    <p>To the fullest extent permitted by law, you agree to indemnify, defend and hold harmless the mensh Parties from and against any and all claims, losses, expenses, demands or liabilities, including reasonable attorneys’ fees arising out of or relating to (i) your access to, use of or alleged use of mensh.app or the Services; (ii) your violation of this Agreement or any representation, warranty, or agreements referenced herein, or any applicable law or regulation; (iii) your violation of any third party right, including without limitation any intellectual property right, publicity, confidentiality, property or privacy right; or (iv) any disputes or issues between you and any third party. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with our defense of such claim. You shall cooperate as fully as reasonably required in the defense of any such claim. mensh reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you. You agree not to settle any matter without the prior written consent of mensh.</p>

    <h2>Accuracy of Materials</h2>
    <p>The materials appearing on our website are not comprehensive and are for general information purposes only. mensh does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website.</p>

    <h2>Links</h2>
    <p>mensh has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control by mensh of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites.</p>

    <h2>Right to Terminate</h2>
    <p>We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service or any other reason. Specifically, the receipt of money from mensh or payment of money to mensh does not entitle you to anything.</p> 

    <h2>Severance</h2>
    <p>Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected.</p>

    <h2>Governing Law</h2>
    <p>These Terms of Service are governed by and construed in accordance with the laws of California. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
  </>);
}
