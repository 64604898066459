import React from "react";
import { RepChange } from "../firestore-types";
import SFactory from "../s-factory";
import { DayMonthYear } from "../pretty-print";

const S = SFactory("rep-change-cell");

const Friend = ({name}: {name: string}) => <span>{name}</span>;

const NewUser = () => <S k="newUser">Joined Mensh!</S>;
const RepaidLoan = () => <S k="repaidLoan">Repaid a loan</S>;
const NewFriend = ({name}: {name: string}) =>
  <S k="newFriend">Became friends with <Friend {...{name}} /></S>;
const Discretionary = () => <span>Admin change</span>;

type RepChangeCellProps = {
  repChange: RepChange;
};

export default function RepChangeCell({repChange}: RepChangeCellProps) {
  return <div className="RepChangeCell">
    <div className="RepChangeCellTitle">
      {repChange.delta > 0 ? '+' : ''}{repChange.delta}: {}
      <Desc {...{repChange}} /> (<DayMonthYear timestamp={repChange.timestamp} />)
    </div>
  </div>;
}

const Desc = ({repChange}: RepChangeCellProps) => {
  switch (repChange.reason) {
  case "NEW_ACCOUNT": return <NewUser />;
  case "LOAN_REPAID": return <RepaidLoan />;
  case "NEW_FRIEND_CONNECTION": return <NewFriend name={repChange.data.other_user_id} />;
  case "ADMIN_DISCRETIONARY": return <Discretionary />;
  }
};
