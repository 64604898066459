import * as React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { sendConnectionInvitation } from "../cloud-functions";
import { FirestoreUser } from "../firestore-types";
import { useAppContext } from "../app-context";
import AccountPendingScreen from "../components/account-pending-screen";
import SFactory from "../s-factory";

const S = SFactory("/add-connection");

type ContentsProps = {
  firestoreUser: FirestoreUser;
};

function Contents({firestoreUser}: ContentsProps) {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const [otherUserID, setOtherUserID] = React.useState<string>("");

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (disabled) {
      console.error("Attempted connection invite while form disabled; ignoring");
      return;
    }
    setDisabled(true);

    console.log("Submitting connection invite…");
    sendConnectionInvitation({other_user_id: otherUserID})
    .then(() => {
      console.log("Connection invite sent");
      navigate("/network");
    })
    .catch(e => {
      console.error("Error submitting connection invite", e);
      alert(`Could not submit connection invite, please try again: ${e}`);
      setDisabled(false);
    });
  }

  return (
    <>
      <h1><S k="title">Invite friend</S></h1>
      <p><S k="connectWithOthers">Connect with friends to increase your reputation and therefore increase your loan maximum, decrease your interest rate, and increase your term maximum.</S></p>
      <p><S k="higherValueFriends">The higher your friend's reputation, the bigger the boost to yours for connecting.</S></p>
      <p><S k="onlyThoseYouTrust">But only connect with friends you trust! If your friend defaults on their loans, you'll lose 1/4 of your reputation and your loan parameters will worsen accordingly.</S></p>
      <form {...{onSubmit}}>
        <input type="text" value={otherUserID} {...{disabled}} onChange={e => setOtherUserID(e.target.value)} />
        <button {...{disabled}} type="submit"><S k="submitButton">Send Invite</S></button>
      </form>
    </>
  );
}

export default function NewLoan() {
  const location = useLocation();
  const context = useAppContext();

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (context.pending) {
    return <AccountPendingScreen firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  } else {
    return <Contents firestoreUser={context.firestoreUser} />;
  }
}
