import SFactory from "../s-factory";

const S = SFactory("error-screen");

type ContentsProps = {
  message: string;
};

export default function AccountPendingScreen({message}: ContentsProps) {
  return (
    <>
      <h1><S k="title">Error!</S></h1>
      <p><S k="body">Fatal error. Please contact support at <a href="mailto:hi@mensh.app">hi@mensh.app</a>.</S></p>
      <p>({message})</p>
    </>
  );
}
