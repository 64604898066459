import React from "react";
import { Loan, applicationTimestamp, isCashSent, totalInterest, dueByTimestamp } from "../firestore-types";
import { Amount, DayMonthYear, InterestRate } from "../pretty-print";
import RepayLoanButton from "./repay-loan-button";
import SFactory from "../s-factory";

const S = SFactory("loan-cell");

type LoanDetailsProps = {
  loan: Loan;
};

export default function LoanDetails({loan}: LoanDetailsProps) {
  return <div className="LoanCell">
    <div className="LoanCellTitle">
      <Amount cents={loan.cents}/> (<DayMonthYear timestamp={applicationTimestamp(loan)} />)
    </div>
    <ul>
      <li><S k="loanStatusLabel">Status</S>: {loan.status}</li>
      <li><S k="durationLabel">Duration</S>: {loan.target_duration} days</li>
      <li><S k="interestRateLabel">Interest rate</S>: <InterestRate basisPoints={loan.interest_rate} /></li>
      {isCashSent(loan) && <>
        <li><S k="dueByLabel">Due by</S>: <DayMonthYear timestamp={dueByTimestamp(loan)} /></li>
        <li><S k="totalInterestLabel">Total interest</S>: <Amount cents={totalInterest(loan)} /></li>
      </>}
    </ul>
    {loan.status === "CASH_SENT" && <RepayLoanButton {...{loan}} />}
  </div>;
}
