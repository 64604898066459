import { Navigate, useLocation, Link } from "react-router-dom";
import { collection, query, where } from "firebase/firestore";
import { db, useQuery } from "../firebase";
import { FirestoreUser, RepChange } from "../firestore-types";
import { useAppContext } from "../app-context";
import RepChangeCell from "../components/rep-change-cell";
import LoadingScreen from "../components/loading-screen";
import AccountPendingScreen from "../components/account-pending-screen";
import SFactory from "../s-factory";

const S = SFactory("/reputation");

type ContentsProps = {
  user: FirestoreUser;
  repChanges: RepChange[];
};

function Contents({user, repChanges}: ContentsProps) {
  return <>
    <div className="StackedBoxes">
      <div>
        <div className="LabelValueBox_Label"><S k="yourReputationScore">Your reputation score</S></div>
        <div className="LabelValueBox_Value">{user.rep}</div>
      </div>
    </div>
    <p>
      <S k="learnMore">To learn more about reputation on Mensh, check out our <Link to="/faq">FAQ</Link>.</S>
    </p>
    <h1><S k="reputationUpdates">Reputation Updates</S></h1>
    <div className="StackedBoxes">
      {repChanges.map(repChange =>
        <RepChangeCell {...{repChange}} key={repChange.id} />)}
    </div>
  </>;
}

export default function Reputation() {
  const location = useLocation();
  const context = useAppContext();

  const q = useQuery<RepChange>(query(collection(db, "rep_changes"), where("user_id", "==", context.firebaseUser?.email || "")));

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (context.pending) {
    return <AccountPendingScreen firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  } else if (q.status !== "Snapshot") {
    return <LoadingScreen />;
  } else {
    return <Contents
      user={context.firestoreUser}
      repChanges={q.value} />;
  }
}
