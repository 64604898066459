import React from "react";
import { Navigate, useParams, useLocation } from "react-router-dom";
import { useLoan } from "../firebase";
import { LoanDetails } from "../components/loan-details";
import { Loan } from "../firestore-types";
import LoadingScreen from "../components/loading-screen";
import { useAppContext } from "../app-context";
import AccountPendingScreen from "../components/account-pending-screen";
import SFactory from "../s-factory";

const S = SFactory("/loan-details");

type ContentsProps = {
  loan: Loan;
};

function Contents({loan}: ContentsProps) {
  return <>
    <h1><S k="title">Loan Details</S></h1>
    <LoanDetails {...{loan}} />
  </>;
}

export default function RepayRouteContents() {
  const location = useLocation();
  const context = useAppContext();

  const { id } = useParams() as {id: string};
  const loan = useLoan(id);

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (context.pending) {
    return <AccountPendingScreen firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  } else if (!loan) {
    return <LoadingScreen />;
  } else {
    return <Contents {...{loan}} />;
  }
}
