import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useUser, useFirestoreUser } from "./firebase";
import { AppContext } from "./app-context";
import Header from "./components/header";
import Footer from "./components/footer";
import ErrorScreen from "./components/error-screen";
import { useTranslation } from "react-i18next";
import { lookUpRoute } from "./routing";

export default function App() {
  const location = useLocation();
  const pathname = location.pathname.slice(1);
  React.useEffect(() => {
    const route = lookUpRoute(pathname);
    document.title = 'Mensh | ' + route.title;

    (window as any).gtag('event', 'screen_view', {'screen_name': route.title});
  }, [pathname]);

  useTranslation(); // XXX: If this is not here, the UI is not re-rendered on language change. This is janky... https://github.com/i18next/react-i18next/issues/1171

  type State =
    | "LOADING"
    | "ERROR"
    | "READY"
  ;

  const user = useUser();
  const firestoreUser = useFirestoreUser(user);

  const state: State =
    user === "LOADING" ? "LOADING" :
    firestoreUser === "FIRESTORE_USER_ERROR" ? "ERROR" :
    firestoreUser === "FIRESTORE_USER_LOADING" ? "LOADING" :
    "READY";

  const signedIn = user !== undefined && user !== "LOADING" && firestoreUser !== undefined && firestoreUser !== "FIRESTORE_USER_ERROR" && firestoreUser !== "FIRESTORE_USER_LOADING";
  const pending = signedIn && (!user.emailVerified || firestoreUser.borrower_status === "PENDING");

  const context: AppContext = signedIn ? {
    signedIn: true,
    pending,
    firebaseUser: user,
    firestoreUser,
  } : {
    signedIn: false,
    pending: false,
    firebaseUser: undefined,
    firestoreUser: undefined,
  };

  return (
    <>
      <Header {...{context}} />
      <main>
        {state === "ERROR" && <ErrorScreen message="corrupt user" />}
        {state === "LOADING" && <></>}
        {state === "READY" && <Outlet {...{context}} />}
      </main>
      <Footer />
    </>
  );
}
