import SFactory from "../s-factory";

const S = SFactory("/contact");

export default function Contact() {
  return (
    <>
      <h1><S k="title">Contact</S></h1>
      <p><S k="body">Reach out to us at <a href="mailto:hi@mensh.app">hi@mensh.app</a>!</S></p>
    </>
  );
}
