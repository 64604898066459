import React from "react";
import AccountPendingScreen from "../components/account-pending-screen";
import { useAppContext } from "../app-context";
import { httpsCallable } from "firebase/functions";
import { functions as firebaseFunctions } from "../firebase";
import { useNavigate, useLocation, useParams, Navigate } from "react-router-dom";
import { useLoan } from "../firebase";
import { CashSentLoan, isCashSent, expectedRepayment } from "../firestore-types";
import { Amount } from "../pretty-print";
import SFactory from "../s-factory";
import LoadingScreen from "../components/loading-screen";

const S = SFactory("/repay-loan");

type ContentsProps = {
  loan: CashSentLoan;
};

function Contents({loan}: ContentsProps) {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const cents = expectedRepayment(loan);

  const onClick = React.useCallback(() => {
    if (disabled) {
      console.error("Attempted to submit loan repayment while disabled; ignoring");
      return;
    }
    setDisabled(true);

    console.log("Submitting loan repayment…");
    const submitLoanRepayment = httpsCallable(firebaseFunctions, 'submitLoanRepayment');
    submitLoanRepayment({
      loan_id: loan.id,
    })
    .then(() => {
      console.log("Loan repayment submitted");
      navigate("/");
    })
    .catch(e => {
      console.error("Error submitting loan repayment", e);
      alert(`Could not submit repayment, please try again: ${e}`);
      setDisabled(false);
    });
  }, [navigate, disabled, loan]);

  return <>
    <h1><S k="title">Repay Loan</S></h1>
    <p><S k="instructions">The repayment amount, including interest, will be <Amount {...{cents}} />. Please send this amount to the Mensh account that sent you the money, and click the button below to confirm repayment. Your repayment will be confirmed by our team.</S></p>
    <button {...{disabled, onClick}}><S k="buttonText">I have repaid <Amount {...{cents}} /></S></button>
  </>;
}

export default function RepayRouteContents() {
  const location = useLocation();
  const context = useAppContext();

  const { id } = useParams() as {id: string};
  const loan = useLoan(id);

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (context.pending) {
    return <AccountPendingScreen firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  } else if (loan && isCashSent(loan)) {
    return <Contents {...{loan}} />;
  } else if (loan) {
    return <>
      <h1>Error</h1>
      <p>This loan has not been paid out, so it is not possible to repay it!</p>
    </>;
  } else {
    return <LoadingScreen />;
  }
}
