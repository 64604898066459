import { collection, query } from "firebase/firestore";
import { db, useQuery } from "../firebase";
import { LoansTable } from "../components/loans-table";
import { isAdmin, Loan } from "../firestore-types";
import AdminInstructions from "../components/admin-instructions";
import { Navigate, useLocation, Link } from "react-router-dom";
import { useAppContext } from "../app-context";
import ErrorScreen from "../components/error-screen";
import AsyncButton from "../components/async-button";
import { oneOff } from "../cloud-functions";

export default function Admin() {
  const location = useLocation();
  const context = useAppContext();

  const loans = useQuery<Loan>(query(collection(db, "loans")));

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (!isAdmin(context.firebaseUser.email)) {
    return <ErrorScreen message="Not authorized!" />;
  } else {
    return <>
      <AdminInstructions />
      <h1>Admin</h1>
      {false && <AsyncButton onClick={() => oneOff({})}>Go</AsyncButton>}
      <Link to="/admin/due-loans">Due Loans</Link>
      {loans.status === "Snapshot" && <>
        <h2>All Loans</h2>
        <LoansTable loans={loans.value} />
      </>}
    </>;
  }
}
