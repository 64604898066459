import * as React from "react";
import { FirebaseUser } from "../firebase";
import { FirestoreUser } from "../firestore-types";
import { resendEmailAddressVerificationEmail } from "../cloud-functions";
import SFactory from "../s-factory";

const S = SFactory("account-pending-screen");

type ContentsProps = {
  firebaseUser: FirebaseUser;
  firestoreUser: FirestoreUser;
};

export default function AccountPendingScreen({firebaseUser, firestoreUser}: ContentsProps) {
  type State =
    | "INITIAL"
    | "SENDING"
    | "SENT"
  ;

  const [state, setState] = React.useState<State>("INITIAL");
  const disabled = state !== "INITIAL";

  async function onClick() {
    try {
      setState("SENDING");
      await resendEmailAddressVerificationEmail({});
      setState("SENT");
    } catch (e) {
      alert(`Error resending verification email! ${e}`);
      setState("INITIAL");
    }
  }

  return (
    <>
      <h1><S k="title">Account Pending</S></h1>
      {firestoreUser.borrower_status === "PENDING" && <>
        <p><S k="yourApplicationIsPending">Your application to the Mensh beta is pending. You should receive an email within 12 hours letting you know your application is approved!</S></p>
      </>}
      {!firebaseUser.emailVerified && <>
        <p><S k="yourEmailAddressNeedsToBeVerified">Your email address still needs to be verified. Please check your inbox.</S></p>
        <button {...{disabled, onClick}}><S k="resendVerificationEmailButton">Resend Verification Email</S></button>
        {state === "SENDING" && <p><S k="resendingEmailStatusText">Resending verification email…</S></p>}
        {state === "SENT" && <p><S k="emailSentStatusText">Verification email sent!</S></p>}
      </>}
    </>
  );
}
