import * as React from "react";
import { Navigate, Link, useNavigate, useLocation } from "react-router-dom";
import { functions as firebaseFunctions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { FirestoreUser, borrowerMinDuration, borrowerMaxDuration, borrowerDefaultDuration, borrowerMinAmount, borrowerMaxAmount, borrowerDefaultAmount, borrowerInterestRate, calculateRepayment } from "../firestore-types";
import { SliderInput } from "../components/slider-input";
import { useAppContext } from "../app-context";
import AccountPendingScreen from "../components/account-pending-screen";
import { Amount, InterestRate } from "../pretty-print";
import SFactory from "../s-factory";
import DollarInput from "../components/dollar-input";
import DayInput from "../components/day-input";
import ErrorStack from "../components/error-stack";

const S = SFactory("/new-loan");

function integerDivide(a: number, b: number): number {
  return Math.round((a - (a % b)) / b); // Math.round just for integer typecast.
}

type ContentsProps = {
  firestoreUser: FirestoreUser;
};

function Contents({firestoreUser}: ContentsProps) {

  const navigate = useNavigate();

  const minDuration = borrowerMinDuration();
  const maxDuration = borrowerMaxDuration(firestoreUser.rep);
  const defaultDuration = borrowerDefaultDuration(firestoreUser.rep);
  const minAmount = integerDivide(borrowerMinAmount(), 100);
  const maxAmount = integerDivide(borrowerMaxAmount(firestoreUser.rep), 100);
  const defaultAmount = integerDivide(borrowerDefaultAmount(firestoreUser.rep), 100);
  const interestRate = borrowerInterestRate();

  const [amount, setAmount] = React.useState<number>(defaultAmount);
  const [duration, setDuration] = React.useState<number>(defaultDuration);

  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [lastErrorString, setLastErrorString] = React.useState<string>("");

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (disabled) {
      console.error("Attempted to submit loan application form while disabled; ignoring");
      return;
    }

    setDisabled(true);

    console.log("Submitting loan application…");
    const submitLoanApplication = httpsCallable(firebaseFunctions, 'submitLoanApplication');
    const submitData: any = {
      cents: 100 * amount,
      target_duration: duration,
      expected_interest_rate: interestRate,
    };
    submitLoanApplication(submitData)
    .then(() => {
      console.log("Loan application submitted");
      navigate("/home");
    })
    .catch(e => {
      console.error("Error submitting loan application", e);
      setLastErrorString(e.toString());
      setDisabled(false);
    });
  }

  return <div className="CenteredStack">
    <h1><S k="title">New Loan</S></h1>
    {lastErrorString !== "" &&
      <ErrorStack errors={[lastErrorString]} />
    }
    <form className="NewLoan" {...{onSubmit}}>
      <SliderInput
        {...{disabled}}
        value={amount} setValue={setAmount}
        min={minAmount} max={maxAmount}
        title={<S k="howMuchDoYouNeed">Amount</S>}
        inputField={DollarInput}
      />
      <SliderInput
        {...{disabled}}
        value={duration} setValue={setDuration}
        min={minDuration} max={maxDuration}
        title={<S k="forHowLong">Duration</S>}
        inputField={DayInput}
      />
      <p>
        <S k="yourInterestRate">Your interest rate will be <InterestRate basisPoints={interestRate} /> APR.</S>
        {" "}
        <S k="yourExpectedRepaymentIs">Your total expected repayment amount will be <Amount cents={calculateRepayment(amount * 100, interestRate, duration)} />.</S>
      </p>
      <button {...{disabled}} type="submit"><S k="submit">Borrow <Amount cents={amount * 100} /></S></button>
      <p><small><S k="contactUsIfNoMoney">Please only push the button once and <Link to="/contact">contact us</Link> if you do not receive your money within 30 minutes.</S></small></p>
    </form>
  </div>;
}

export default function NewLoan() {
  const location = useLocation();
  const context = useAppContext();

  if (!context.signedIn) {
    return <Navigate replace to="/create-account" state={{redirectURL: location.pathname}} />;
  } else if (context.pending) {
    return <AccountPendingScreen firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  } else {
    return <Contents firestoreUser={context.firestoreUser} />;
  }
}
