import { Trans } from "react-i18next";

type Child =
  | JSX.Element
  | string
  | undefined
;

type Children = Child | Child[];

export default function SFactory(ns: string) {
  return (props: {k: string, children: Children}) => (<Trans {...{ns}} i18nKey={props.k}>{props.children}</Trans>);
}
