type Props = {
  errors: string[];
}

const ErrorStack = ({errors}: Props) => {
  return <div className="StackedBoxes">
    {errors.map((e, index) => 
      <div className="ErrorBox" key={index}>
        <h1>Error</h1>
        <div>{e}</div>
      </div>
    )}
  </div>;
};

export default ErrorStack;
