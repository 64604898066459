import React from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./i18n.json";
import { useTranslation } from "react-i18next";

function getFirstBrowserLanguage(): string | null {
  // https://stackoverflow.com/questions/1043339/javascript-for-detecting-browser-language-preference
  // XXX: I'm not even sure how well this actually works, or whether it works at all.
        var nav = window.navigator as any,
            browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'],
            i,
            language,
            len,
            shortLanguage = null;

        // support for HTML 5.1 "navigator.languages"
        if (Array.isArray(nav.languages)) {
            for (i = 0; i < nav.languages.length; i++) {
                language = nav.languages[i];
                len = language.length;
                if (!shortLanguage && len) {
                    shortLanguage = language;
                }
                if (language && len>2) {
                    return language;
                }
            }
        }

        // support for other well known properties in browsers
        for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
            language = nav[browserLanguagePropertyKeys[i]];
            //skip this loop iteration if property is null/undefined.  IE11 fix.
            if (language == null) { continue; } 
            len = language.length;
            if (!shortLanguage && len) {
                shortLanguage = language;
            }
            if (language && len > 2) {
                return language;
            }
        }

        return shortLanguage;
    }

export type Language =
  | "en"
  | "es"
;

function isLanguage(s: any): s is Language {
  return s === "en" || s === "es";
}

function savedLanguage(): Language | undefined {
  const SL = localStorage.userLanguageSelection;
  if (isLanguage(SL)) return SL;
}

function initialLanguage(): Language {
  const SL = savedLanguage();
  if (isLanguage(SL)) return SL;

  const FBL = getFirstBrowserLanguage()?.slice(0, 2);
  if (isLanguage(FBL)) return FBL;

  return 'en';
}

export function saveLanguage(lang: Language) {
  localStorage.userLanguageSelection = lang;
}

export function useLanguageSelection(): [Language, (s: Language) => void] {
  // XXX: THIS SHOULD ONLY BE USED FROM ONE PLACE.
  const {i18n} = useTranslation();
  const [language, rawSetLanguage] = React.useState<Language>(initialLanguage());
  const setLanguage = React.useCallback((newLanguage: Language) => {
    console.log(`Changing language to ${newLanguage}`);
    i18n.changeLanguage(newLanguage);
    saveLanguage(newLanguage);
    rawSetLanguage(newLanguage);
  }, [i18n]);
  return [language, setLanguage];
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: initialLanguage(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });

export default i18n;
