import { Navigate, useLocation } from "react-router-dom";
import { collection, query, where } from "firebase/firestore";
import { db, useQuery } from "../firebase";
import { Loan } from "../firestore-types";
import { useAppContext } from "../app-context";
import LoanCell from "../components/loan-cell";
import LoadingScreen from "../components/loading-screen";
import AccountPendingScreen from "../components/account-pending-screen";
import SFactory from "../s-factory";

const S = SFactory("/loans");

type ContentsProps = {
  loans: Loan[];
};

function Contents({loans}: ContentsProps) {
  return <>
    <h1><S k="title">Loans</S></h1>
    {loans.length === 0 && <p>(No loans yet!)</p>}
    <div className="StackedBoxes">
      {loans.map(loan => <LoanCell {...{loan}} key={loan.id} />)}
    </div>
  </>;
}

export default function Loans() {
  const location = useLocation();
  const context = useAppContext();

  const loans = useQuery<Loan>(query(collection(db, "loans"), where("user_id", "==", context.firebaseUser?.email || "")));

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (context.pending) {
    return <AccountPendingScreen firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  } else if (loans.status !== "Snapshot") {
    return <LoadingScreen />;
  } else {
    return <Contents loans={loans.value} />;
  }
}
