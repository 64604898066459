import * as React from "react";
import { Navigate, Link, useLocation } from "react-router-dom";
import { FirebaseUser } from "../firebase";
import { FirestoreUser } from "../firestore-types";
import { useAppContext } from "../app-context";
import SFactory from "../s-factory";

const S = SFactory("/account");

type ContentsProps = {
  firebaseUser: FirebaseUser;
  firestoreUser: FirestoreUser;
};

function Contents({firebaseUser, firestoreUser}: ContentsProps) {
  const disabled = true;

  const firstName = firestoreUser.first_name;
  const lastName = firestoreUser.last_name;
  const birthday = firestoreUser.birthday;
  const venmoUsername = firestoreUser.venmo_username;
  const zelleUsername = firestoreUser.zelle_username;
  const cashappUsername = firestoreUser.cashapp_username;

  return (
    <>
      <h1><S k="title">Account Details</S></h1>
      <form>
        <label><S k="firstNameLabel">First name</S><br/>
          <input {...{disabled}} type="text" value={firstName}  />
        </label>
        <label><S k="lastNameLabel">Last name</S><br/>
          <input {...{disabled}} type="text" value={lastName}  />
        </label>
        <label><S k="birthdayLabel">Birthday</S><br/>
          <input {...{disabled}} type="date" value={birthday}  />
        </label>
        <label><S k="venmoLabel">Venmo account name</S><br/>
          <input {...{disabled}} type="text" value={venmoUsername}  />
        </label>
        <label><S k="zelleLabel">Zelle account name</S><br/>
          <input {...{disabled}} type="text" value={zelleUsername}  />
        </label>
        <label><S k="cashappLabel">Cash App account name</S><br/>
          <input {...{disabled}} type="text" value={cashappUsername}  />
        </label>
      </form>
      <p><S k="toUpdateYourAccountDetails">To update your account details, <Link to="/contact">contact us</Link>.</S></p>
    </>
  );
}

export default function Account() {
  const location = useLocation();
  const context = useAppContext();

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else {
    return <Contents firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  }
}
