import React from "react";

type SliderInputProps = {
  value: number;
  setValue: (newValue: number) => void;
  min: number;
  max: number;
  disabled?: boolean;
};

export default function SliderInput({value, setValue, min, max, disabled}: SliderInputProps) {
  return (
    <div className="SliderTextField DollarInput">
      <span>$</span>
      <input type="number" {...{value, min, max, disabled}} onChange={e => setValue(parseFloat(e.target.value))} />
    </div>
  );
}
