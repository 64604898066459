import React from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { doc, collection, query, where } from "firebase/firestore";
import { db, useQuery, useDoc } from "../firebase";
import { FriendGraphNode, ConnectionInvitation } from "../firestore-types";
import { useAppContext } from "../app-context";
import AccountPendingScreen from "../components/account-pending-screen";
import LoadingScreen from "../components/loading-screen";
import { acceptConnectionInvitation, cancelConnectionInvitation, removeConnection } from "../cloud-functions";
import SFactory from "../s-factory";
import AsyncButton from "../components/async-button";

const S = SFactory("/network");

type ContentsProps = {
  friendGraphNode: FriendGraphNode;
  incomingInvites: ConnectionInvitation[];
  outgoingInvites: ConnectionInvitation[];
};

function Contents({friendGraphNode, incomingInvites, outgoingInvites}: ContentsProps) {
  const navigate = useNavigate();
  const onClick = React.useCallback(() => navigate("/add-connection"), [navigate]);
  return <>
    <h1><S k="title">Your Network</S></h1>
    <div><button {...{onClick}}><S k="inviteNewConnection">Invite friend to connect</S></button></div>
    <h1><S k="incomingInvitations">Friend requests received</S></h1>
    <ul>
      {incomingInvites.map(
        invite => (
          <li key={invite.from}>
            {invite.from}
            <AsyncButton onClick={async () => acceptConnectionInvitation({connection_invitation_id: invite.id})}><S k="acceptIncomingInvitationButton">Accept</S></AsyncButton>
            <AsyncButton onClick={async () => cancelConnectionInvitation({connection_invitation_id: invite.id})}><S k="rejectIncomingInvitationButton">Reject</S></AsyncButton>
          </li>
        )
      )}
    </ul>
    <h1><S k="outgoingInvitations">Friend requests sent</S></h1>
    <ul>
      {outgoingInvites.map(
        invite => (
          <li key={invite.to}>
            {invite.to}
          </li>
        )
      )}
    </ul>
    <h1><S k="yourNetwork">Your friend network</S></h1>
    <ul>
      {friendGraphNode.friend_ids.map(
        user_id => (
          <li key={user_id}>
            {user_id}
            <AsyncButton onClick={async () => removeConnection({other_user_id: user_id})}><S k="removeConnectionButton">Remove</S></AsyncButton>
          </li>
        )
      )}
    </ul>
  </>;
}

export default function Network() {
  const location = useLocation();
  const context = useAppContext();

  const email = context.firebaseUser?.email || "NOTHING";
  const ref = React.useMemo(() => doc(collection(db, "connections"), email), [email]);
  const incomingInvites = useQuery<ConnectionInvitation>(query(collection(db, "connection_invitations"), where("to", "==", email)));
  const outgoingInvites = useQuery<ConnectionInvitation>(query(collection(db, "connection_invitations"), where("from", "==", email)));
  const connections = useDoc<FriendGraphNode>(ref);

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (context.pending) {
    return <AccountPendingScreen firebaseUser={context.firebaseUser} firestoreUser={context.firestoreUser} />;
  } else if (connections.status !== "Snapshot"
          || incomingInvites.status !== "Snapshot"
          || outgoingInvites.status !== "Snapshot") {
    return <LoadingScreen />;
  } else {
    return <Contents
      friendGraphNode={connections.value || {friend_ids: []}}
      incomingInvites={incomingInvites.value}
      outgoingInvites={outgoingInvites.value}
    />;
  }
}
