import React from "react";
import { Loan, dueByTimestamp, totalInterest, isCashSent } from "../firestore-types";
import { ppAmount, ppInterestRate, ppTimestamp, ppLoanStatus } from "../pretty-print";
import SFactory from "../s-factory";

const S = SFactory("loans-table");

type LoanRowProps = {
  loan: Loan;
  extraCell: React.FunctionComponent<any> | any;
  showUser?: boolean;
};

function LoanRow({loan, extraCell, showUser}: LoanRowProps) {
  const ExtraCell = extraCell;
  return <tr>
    {showUser && <td>{loan.user_id}</td>}
    <td>{ppAmount(loan.cents)}</td>
    <td>{ppInterestRate(loan.interest_rate)}</td>
    <td>{ppLoanStatus(loan)}</td>
    <td>{isCashSent(loan) && ppTimestamp(dueByTimestamp(loan))}</td>
    <td>{isCashSent(loan) && ppAmount(totalInterest(loan))}</td>
    {extraCell && <td><ExtraCell {...{loan}} /></td>}
  </tr>;
}

type LoansTableProps = {
  loans: Loan[];
  extraCell?: React.FunctionComponent<any>;
  extraColumnTitle?: string;
  showUser?: boolean;
};

export function LoansTable({loans, extraCell, extraColumnTitle, showUser}: LoansTableProps) {
  if (extraColumnTitle === undefined) {
    extraColumnTitle = "Extra";
  }
  return <table>
    <thead>
      <tr>
        {showUser && <th><S k="userIDColumnHeader">User</S></th>}
        <th><S k="amountColumnHeader">Amount</S></th>
        <th><S k="interestRateColumnHeader">Interest Rate</S></th>
        <th><S k="loanStatusColumnHeader">Status</S></th>
        <th><S k="dueByColumnHeader">Due By</S></th>
        <th><S k="totalInterestColumnHeader">Total Interest</S></th>
        {extraCell && <th>{extraColumnTitle}</th>}
      </tr>
    </thead>
    <tbody>
    {loans.map(loan => (
      <LoanRow key={loan.id} {...{loan, extraCell, showUser}} />
    ))}
    </tbody>
  </table>;
}
