import React from "react";
import DollarInput from "./dollar-input";
import DayInput from "./day-input";

type Child =
  | JSX.Element
  | string
  | undefined
;

type Children = Child | Child[];

type SliderInputProps = {
  title: Children;
  inputField: typeof DollarInput | typeof DayInput;
  finePrint?: string;
  value: number;
  setValue: (newValue: number) => void;
  min: number;
  max: number;
  disabled?: boolean;
};

export function SliderInput({title, inputField, finePrint, value, setValue, min, max, disabled}: SliderInputProps) {
  const InputField = inputField;
  return (
    <div className="SliderInput">
      <label>{title}</label>
      <div className="Controls">
        <input type="range" {...{value, min, max, disabled}} onChange={e => setValue(parseFloat(e.target.value))} />
        <InputField {...{value, min, max, disabled, setValue}} />
      </div>
      {finePrint && <><br/><small>{finePrint}</small></>}
    </div>
  );
}
