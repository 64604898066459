import React from "react";
import SFactory from "../s-factory";

const S = SFactory("day-text-input");

type SliderInputProps = {
  value: number;
  setValue: (newValue: number) => void;
  min: number;
  max: number;
  disabled?: boolean;
};

export default function SliderInput({value, setValue, min, max, disabled}: SliderInputProps) {
  return (
    <div className="SliderTextField DayInput">
      <input type="number" {...{value, min, max, disabled}} onChange={e => setValue(parseFloat(e.target.value))} />
      <span><S k="label">days</S></span>
    </div>
  );
}
