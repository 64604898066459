import React from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAppContext } from "../app-context";
import SFactory from "../s-factory";

const S = SFactory("/sign-in");

function Contents() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      setDisabled(true);

      // IMPORTANT (GH #20): We always use all-lowercase versions of email addresses.
      const actualEmail = email.trim().toLowerCase();

      const cred = await signInWithEmailAndPassword(auth, actualEmail, password);
      const user = cred.user;
      console.log(`Successfully signed in as ${user.email}`);
    } catch (e) {
      alert(`Error signing in! ${e}`);
      setDisabled(false);
    }
  }

  return <div className="CenteredStack">
    <button disabled={disabled} onClick={() => navigate('/create-account')}><S k="createNewAccount">Create new account</S></button>
    <h1><S k="title">Sign In</S></h1>
    <form {...{onSubmit}} className="CenteredStack">
      <label><S k="emailAddressLabel">Email address</S><br/>
        <input {...{disabled}} type="text" autoComplete="username" value={email} onChange={e => setEmail(e.target.value)} />
      </label>
      <label><S k="passwordLabel">Password</S><br/>
        <input {...{disabled}} type="password" autoComplete="current-password" value={password} onChange={e => setPassword(e.target.value)} />
      </label>
      <button type="submit" disabled={disabled}><S k="submit">Sign in</S></button>
      <p>
        <Link to="/forgot-password"><S k="forgotPassword">Forgot password?</S></Link>
      </p>
    </form>
  </div>;
}

export default function SignIn() {
  const location = useLocation();
  const context = useAppContext();
  const redirectURL = (location.state as any)?.redirectURL || "/";

  if (context.signedIn) {
    return <Navigate replace to={redirectURL} />;
  } else {
    return <Contents />;
  }
}
