import * as React from "react";
import SFactory from "../s-factory";
import { BigGetCashNow } from "../components/get-cash-now";
import { useTranslation } from "react-i18next";

const S = SFactory("/landing");

export default function Landing() {
  useTranslation(); // XXX: If this is not here, the UI is not re-rendered on language change. This is janky... https://github.com/i18next/react-i18next/issues/1171
  return (
    <div className="LandingPage">
      <h1><S k="header">Loans secured by social capital</S></h1>
      <p><S k="summary">Borrow money, build your reputation, vouch for your friends, borrow more!</S></p>
      <div><BigGetCashNow /></div>
      <p><S k="get25Dollars">Start with $25 at 0% — reach $1000+ at 0% over time</S></p>
      <div className="Explainer">
        <h1><S k="howItWorks">How it works</S></h1>
        <div className="Box"><S k="borrowMoney">Borrow money, no credit check, no strings attached</S></div>
        <div className="Box"><S k="repayOnTime">Repay on time to build reputation</S></div>
        <div className="Box"><S k="addFriends">Add friends on Mensh to build reputation</S></div>
        <div className="Box"><S k="borrowMore">The higher your reputation, the more you can borrow!</S></div>
      </div>
    </div>
  );
}
