import React from "react";
import { useNavigate } from "react-router-dom";
import { Loan } from "../firestore-types";
import SFactory from "../s-factory";

const S = SFactory("repay-loan-button");

type RepayLoanButtonProps = {
  loan: Loan;
};

export default function RepayLoanButton({loan}: RepayLoanButtonProps) {
  const navigate = useNavigate();
  const onClick = React.useCallback(() => {
    navigate(`/repay-loan/${loan.id}`);
  }, [loan, navigate]);
  return <button className="RepayLoanButton" {...{onClick}}><S k="text">Repay</S></button>;
}
