import SFactory from "../s-factory";
import { Link } from "react-router-dom";
import { Twitter, Linkedin, Youtube } from "react-bootstrap-icons";

const S = SFactory("footer");

const socialMediaIconSize = 30;

export default function Footer() {
  return <footer>
    <div className="FooterLinks">
      <S k="copyright">©Mensh 2022</S> |{" "}
      <Link to="/contact"><S k="contact">Contact</S></Link> |{" "}
      <Link to="/tos"><S k="termsOfService">Terms of Service</S></Link> |{" "}
      <Link to="/cookies"><S k="cookiePolicy">Cookie Policy</S></Link> |{" "}
      <Link to="/privacy"><S k="privacyPolicy">Privacy Policy</S></Link>
    </div>
    <div className="FooterSocialLogos">
      <a href="https://twitter.com/MenshApp"><Twitter size={socialMediaIconSize} /></a>
      <a href="https://www.linkedin.com/company/menshapp/"><Linkedin size={socialMediaIconSize} /></a>
      <a href="https://www.youtube.com/channel/UCCLO6FHQ854b-RvcyZ4Cimw"><Youtube size={socialMediaIconSize} /></a>
    </div>
  </footer>;
}
