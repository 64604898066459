import React from "react";

type Child =
  | JSX.Element
  | string
  | undefined
;

type Children = Child | Child[];

type AsyncButtonProps = {
  onClick: () => Promise<any>;
  children: Children;
  disabled?: boolean;
};

export default function AsyncButton({onClick, children, disabled}: AsyncButtonProps) {
  const [localDisabled, setLocalDisabled] = React.useState<boolean>(false);
  async function wrappedOnClick() {
    setLocalDisabled(true);
    try {
      await onClick();
    } finally {
      setLocalDisabled(false);
    }
  }
  return <button disabled={disabled || localDisabled} onClick={wrappedOnClick}>{children}</button>;
}
