// Import the functions you need from the SDKs you need
import * as React from "react";
import { FirebaseApp, initializeApp } from "firebase/app";
import * as firebaseAuth from "firebase/auth";
import { Functions as FirebaseFunctions, getFunctions } from "firebase/functions";
import { collection, doc, query, onSnapshot, Firestore, getFirestore, Query, DocumentReference, QueryConstraint } from "firebase/firestore";
import { User } from "firebase/auth";
import { FirestoreUser, Loan, isFirestoreUser } from "./firestore-types";

export type FirebaseUser = User;

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPWgdND0on3qm6asOrmSgjfWlbcIVcQIQ",
  authDomain: "mensh-app.firebaseapp.com",
  projectId: "mensh-app",
  storageBucket: "mensh-app.appspot.com",
  messagingSenderId: "1016437421289",
  appId: "1:1016437421289:web:15ad549e7ccca67674ec78",
  measurementId: "G-1TKS0LGGP3"
};

export let app: FirebaseApp;
export let auth: firebaseAuth.Auth;
export let db: Firestore;
export let functions: FirebaseFunctions;

function init() {
  console.log("Initializing Firebase");
  app = initializeApp(firebaseConfig);
  auth = firebaseAuth.getAuth(app);
  db = getFirestore(app);
  functions = getFunctions();
}

export function signOut() {
  firebaseAuth.signOut(auth);
}

export function useUser() {
  const [user, setUser] = React.useState<"LOADING" | User | undefined>("LOADING");
  React.useEffect(() => {
    let interval: any;
    const deregister = auth.onIdTokenChanged(function(user) {
      clearInterval(interval);
      if (user) {
        console.log(`User signed in: ${user.email}`);
        setUser(user);
        if (!user.emailVerified) {
          interval = setInterval(() => {
            console.log("Reloading user to see if email address has been verified");
            user.reload();
            if (user.emailVerified) {
              clearInterval(interval);
            }
          }, 1000 * 5);
        }
      } else {
        console.log(`User signed out`);
        setUser(undefined);
      }
    }, function(error) {
      console.log(`User auth error: ${error}`);
      setUser(undefined);
      console.log(error);
    });

    return () => {
      deregister();
      clearInterval(interval);
    };
  });
  return user;
}

export function useAnyFirestoreUser(email: string | undefined) {
  const [firestoreUser, setFirestoreUser] = React.useState<FirestoreUser | "FIRESTORE_USER_LOADING" | "FIRESTORE_USER_ERROR" | undefined>(
    email === "LOADING" ? "FIRESTORE_USER_LOADING" :
    email ? "FIRESTORE_USER_LOADING" :
    undefined
  );
  React.useEffect(() => {
    if (email === "LOADING") {
      setFirestoreUser("FIRESTORE_USER_LOADING");
    } if (email) {
      const ref = doc(collection(db, 'users'), email);
      setFirestoreUser("FIRESTORE_USER_LOADING");
      const unsubscribe = onSnapshot(ref,
        snapshot => {
          if (!snapshot.exists()) {
            console.error("User does not exist in Firestore");
            setFirestoreUser(undefined);
          } else {
            const data = snapshot.data();
            if (!isFirestoreUser(data)) {
              console.error("Corrupt user snapshot", snapshot);
              setFirestoreUser("FIRESTORE_USER_ERROR");
            } else {
              console.log("Got new user snapshot", snapshot);
              setFirestoreUser(data);
            }
          }
        },
        error => {
          console.error("Error fetching Firestore user", error);
          setFirestoreUser(undefined);
        },
      );
      return unsubscribe;
    } else {
      setFirestoreUser(undefined);
    }
  }, [email]);
  return firestoreUser;
}

export function useFirestoreUser(user: "LOADING" | User | undefined) {
  return useAnyFirestoreUser(
    user === "LOADING" ? "LOADING" :
    (user?.email || undefined)
  );
}

type QueryPending = {
  status: "Pending";
};

type QuerySnapshot<T> = {
  status: "Snapshot";
  value: T[];
};

type QueryError = {
  status: "Error";
  message: string;
};

type QueryStatus<T> =
  | QueryPending
  | QuerySnapshot<T>
  | QueryError
  ;

// XXX: useMemo at all usage sites
export function useQuery<T>(query: Query): QueryStatus<T> {
  const [result, setResult] = React.useState<QueryStatus<T>>({status: "Pending"});
  React.useEffect(() => {
    return onSnapshot(
      query,
      snapshot => {
        setResult({
          status: "Snapshot",
          value: snapshot.docs.map(doc => (doc.data() as T)),
        });
      },
      error => {
        console.error("Error executing query", error);
        setResult({status: "Error", message: error.toString()});
      },
    );
  });
  return result;
}

type DocPending = {
  status: "Pending";
};

type DocSnapshot<T> = {
  status: "Snapshot";
  value: T | undefined;
};

type DocError = {
  status: "Error";
  message: string;
};

type DocStatus<T> =
  | DocPending
  | DocSnapshot<T>
  | DocError
;

// XXX: useMemo at all usage sites
export function useDoc<T>(ref: DocumentReference): DocStatus<T> {
  const [result, setResult] = React.useState<DocStatus<T>>({status: "Pending"});
  React.useEffect(() => {
    return onSnapshot(
      ref,
      snapshot => {
        setResult({
          status: "Snapshot",
          value: snapshot.data() as T,
        });
      },
      error => {
        console.error("Error getting doc", error);
        setResult({status: "Error", message: error.toString()});
      },
    );
  }, [ref]);
  return result;
}

export function useLoan(id: string) {
  const [loan, setLoan] = React.useState<Loan | undefined>();
  React.useEffect(() => {
    return onSnapshot(
      doc(collection(db, "loans"), id),
      snapshot => {
        if (snapshot.exists()) {
          setLoan(snapshot.data() as Loan);
        } else {
          console.error("Invalid loan id", id);
          setLoan(undefined);
        }
      },
      error => {
        console.error("Error fetching user loan", error);
        setLoan(undefined);
      },
    );
  }, [id]);

  return loan;
}

export function useThoseLoans(constraint: QueryConstraint) {
  return useQuery<Loan>(query(collection(db, "investments"), constraint));
}

init();
