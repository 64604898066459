import { collection, query } from "firebase/firestore";
import { db, useQuery } from "../firebase";
import AdminLoanCell from "../components/admin-loan-cell";
import { isAdmin, Loan, dueByTimestamp, isCashSent, isRepaymentClaimed } from "../firestore-types";
import AdminInstructions from "../components/admin-instructions";
import { Navigate, useLocation } from "react-router-dom";
import { useAppContext } from "../app-context";
import ErrorScreen from "../components/error-screen";

export default function Admin() {
  const location = useLocation();
  const context = useAppContext();

  const now = Date.now() / 1000;
  const loans = useQuery<Loan>(query(collection(db, "loans")));

  if (!context.signedIn) {
    return <Navigate replace to="/sign-in" state={{redirectURL: location.pathname}} />;
  } else if (!isAdmin(context.firebaseUser.email)) {
    return <ErrorScreen message="Not authorized!" />;
  } else {
    return <>
      <AdminInstructions />
      <h1>Due Loans</h1>
      {loans.status === "Snapshot" &&
        <div className="StackedBoxes">
          {loans.value
            .filter(isCashSent)
            .filter(loan => dueByTimestamp(loan) < now)
            .filter(loan => !isRepaymentClaimed(loan))
            .map(loan => <AdminLoanCell {...{loan}} key={loan.id} />)
          }
        </div>
      }
    </>;
  }
}
